"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Img = _interopRequireDefault(require("./Img"));
var _VideoLabel = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/VideoLabel"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Rating = _interopRequireDefault(require("./Rating"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _router = require("next/router");
var _TenantContext = require("../context/TenantContext");
const _excluded = ["img", "rating", "title", "place", "themes", "duration", "time", "videoUrl", "linkProps", "large", "loading", "fullUrl", "prices", "canBuyTickets", "timeSlotChildren", "icon", "activityState"];
var __jsx = _react.default.createElement;
const ActivityTile = _ref => {
  let {
      img,
      rating,
      title,
      place,
      themes,
      duration,
      time,
      videoUrl,
      linkProps,
      large = false,
      loading = false,
      fullUrl,
      prices,
      canBuyTickets,
      timeSlotChildren,
      icon,
      activityState
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const freeAccess = prices?.length === 1 && prices[0].price === 0;
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    locale
  } = (0, _router.useRouter)();
  return __jsx(Container, (0, _extends2.default)({
    itemScope: true,
    itemType: "http://schema.org/ListItem",
    className: large ? 'tile-xl' : 'tile-l',
    canBuyTickets: activityState !== 'UNAVAILABLE'
  }, rest), loading ? __jsx(_react.default.Fragment, null, __jsx(_grid.Box, {
    style: {
      width: '100%',
      paddingBottom: large ? 'calc(10 / 16 * 100%)' : 'calc(3 / 4 * 100%)',
      position: 'relative'
    }
  }, __jsx(_grid.Box, {
    style: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    }
  }, __jsx(_reactLoadingSkeleton.default, {
    width: "100%",
    height: "100%"
  }))), __jsx(Information, null, __jsx(_reactLoadingSkeleton.default, {
    width: "30%",
    height: "100%"
  }), __jsx(_reactLoadingSkeleton.default, {
    width: "80%",
    height: "100%"
  }), __jsx(_reactLoadingSkeleton.default, {
    width: "30%",
    height: "100%"
  }))) : __jsx(_react.default.Fragment, null, __jsx(Photo, null, img && __jsx(_Img.default, (0, _extends2.default)({
    loading: "lazy",
    layout: "ratio",
    ratio: large ? 10 / 16 : 3 / 4
  }, img)), __jsx(_VideoLabel.default, {
    videoUrl: videoUrl
  })), __jsx(Information, null, !!rating && brandConfig.hasReviews && __jsx(_Rating.default, {
    value: rating
  }), __jsx("div", null, __jsx(_Title.default, {
    variant: "small"
  }, title), __jsx(Metadata, null, [place, themes, duration, time].filter(i => !!i).join(', '))), __jsx(StyledDateContainer, null, timeSlotChildren), prices && __jsx(PurchaseContainer, null, __jsx(Prices, null, freeAccess && __jsx(Price, null, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "Eo63xC",
    defaultMessage: [{
      "type": 0,
      "value": "gratis toegang"
    }]
  }))), !freeAccess && prices.map(price => __jsx(Price, {
    key: price.name
  }, __jsx(_Body.default, null, !!price.price && __jsx(_reactIntl.FormattedNumber, {
    format: "EUR",
    value: price.price
  }), !price.price && __jsx(_reactIntl.FormattedMessage, {
    id: "I89Exj",
    defaultMessage: [{
      "type": 0,
      "value": "gratis"
    }]
  })), ' ', __jsx(_Body.default, null, !price.price ? __jsx(_reactIntl.FormattedMessage, {
    id: "gck65y",
    defaultMessage: [{
      "type": 0,
      "value": "voor "
    }, {
      "type": 1,
      "value": "name"
    }],
    values: {
      name: price.name.toLowerCase()
    }
  }) : __jsx(_reactIntl.FormattedMessage, {
    id: "ODI2lc",
    defaultMessage: [{
      "type": 0,
      "value": "per "
    }, {
      "type": 1,
      "value": "name"
    }],
    values: {
      name: locale !== 'de' ? price.name.toLowerCase() : price.name
    }
  }))))), activityState === 'AVAILABLE' && linkProps && __jsx(_link.default, (0, _extends2.default)({}, linkProps, {
    passHref: true,
    legacyBehavior: true
  }), __jsx(_Button.default, {
    as: "a",
    style: {
      zIndex: 1
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "fE53tb",
    defaultMessage: [{
      "type": 0,
      "value": "Ticket kopen"
    }]
  }))), activityState === 'EXTERAL' && __jsx(_Body.default, {
    variant: "large",
    style: {
      fontWeight: 500
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "UNc7q2",
    defaultMessage: [{
      "type": 0,
      "value": "Meer info"
    }]
  })), activityState === 'UNAVAILABLE' && __jsx(_Body.default, {
    variant: "large",
    style: {
      fontWeight: 500
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "hL1OMg",
    defaultMessage: [{
      "type": 0,
      "value": "Niet beschikbaar"
    }]
  })), activityState === 'SOLD_OUT' && __jsx(_Body.default, {
    variant: "large",
    style: {
      fontWeight: 500
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "+CZOlz",
    defaultMessage: [{
      "type": 0,
      "value": "Uitverkocht"
    }]
  }))), linkProps && __jsx(_link.default, (0, _extends2.default)({}, linkProps, {
    passHref: true,
    legacyBehavior: true
  }), __jsx(LinkOverlay, null))), icon && __jsx(IconWrapper, null, icon), __jsx("meta", {
    itemProp: "name",
    content: `${title} - ${place} - ${time ?? ''}`
  }), fullUrl && __jsx("meta", {
    itemProp: "url",
    content: fullUrl
  })));
};
const Container = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__Container",
  componentId: "hgcwzp-0"
})(["display:block;position:relative;opacity:", ";& > img{width:100%;}& > * + *{margin-top:0.8rem;}"], ({
  canBuyTickets
}) => canBuyTickets ? 1 : 0.5);
const Photo = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__Photo",
  componentId: "hgcwzp-1"
})(["position:relative;margin-bottom:", ";"], ({
  theme
}) => theme.spacing['30_Small']);
const Information = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__Information",
  componentId: "hgcwzp-2"
})(["& > * + *{margin-top:0.4rem;}"]);
const Metadata = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ActivityTile__Metadata",
  componentId: "hgcwzp-3"
})([""]);
const PurchaseContainer = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__PurchaseContainer",
  componentId: "hgcwzp-4"
})(["margin-top:1.2rem;display:flex;justify-content:space-between;align-items:center;column-gap:0.8rem;> a{flex-shrink:0;margin-bottom:auto;}"]);
const Prices = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__Prices",
  componentId: "hgcwzp-5"
})([""]);
const Price = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__Price",
  componentId: "hgcwzp-6"
})(["& > *{display:inline;line-height:1.5em;&:first-child{font-weight:700;}}"]);
const LinkOverlay = _styledComponents.default.a.withConfig({
  displayName: "ActivityTile__LinkOverlay",
  componentId: "hgcwzp-7"
})(["position:absolute;top:0;right:0;bottom:0;left:0;"]);
const StyledDateContainer = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__StyledDateContainer",
  componentId: "hgcwzp-8"
})(["font-weight:700;color:", ";display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;"], ({
  theme
}) => theme.colorAssignments.rating ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.rating) : theme.colors.secondary['30']);
const IconWrapper = _styledComponents.default.div.withConfig({
  displayName: "ActivityTile__IconWrapper",
  componentId: "hgcwzp-9"
})(["z-index:1;position:absolute;right:1.2rem;top:1.2rem;"]);
var _default = exports.default = ActivityTile;